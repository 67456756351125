<template>
    <div>

        <list :fs="couponFields" api="coupons" editLink="/admin/coupons" addLink="/admin/coupons/new"></list>
    </div>
</template>

<script>
import List from "@/components/List";
import { couponFields } from "@/config";

export default {
    data() {
        return {
            couponFields: couponFields
        };
    },
    components: {
        List
    }
};
</script>